// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Constants
import { Brands } from '@/Constants/Global/Companies'
import { Vuex }   from '@/Constants/Global/Vuex'

// Dependencies
import Vue from 'vue'

// Store
import Store from '@/Store/Global/Default'

// Mixin Extend
const MixinCompany = Vue.extend({
	data: function() {
		return {
			__ADMINISTRATOR_COMPANY: undefined
		}	
	},

	mounted: function() {
		this._initCompany()
	},
	
	methods: {
		_getName: function(rut: string) {
			switch (rut) {
				case Brands.Janssen.RUT: return Brands.Janssen.NAME
				case Brands.KREIS.RUT: return Brands.KREIS.NAME
			}
		},

		_initCompany: function() {
			const user = Store.getters.getStoredUser
			if (user) {
				const { _idCompanyAdministrator } = user
				const company = Store.getters.getStoredCompanyById(_idCompanyAdministrator)
				if (company) this.$data.__ADMINISTRATOR_COMPANY = this._getName(company.rut)
			}
		},

		getAdministratorCompanyName: function() {
			return this.$data.__ADMINISTRATOR_COMPANY
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initCompany')
	}
})

// Exports
export default MixinCompany
export type MixinCompanyRef = InstanceType<typeof MixinCompany>