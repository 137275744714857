// Dependencies
import Vue from 'vue'

// Mixin Extend
const MixinBase = Vue.extend({
	data: function() {
		return {
			__Toast:  {
				append: false,
				autoHideDelay: 4000,
				toaster: 'b-toaster-bottom-right',
				variant: 'default'
			}
		}
	},

	methods: {
		showToast: function(title: string, message: string, variant = 'default') {
			const { __Toast } = this.$data
			this.$bvToast.toast(message, {
				title, variant,
				autoHideDelay: __Toast.autoHideDelay,
				appendToast: __Toast.append,
				toaster: __Toast.toaster
			})
		}
	}
})

// Exports
export default MixinBase
export type MixinBaseRef = InstanceType<typeof MixinBase>