export const Breakpoints = {
	XSmall    : 308,
	Small     : 576,
	Medium    : 768,
	Large     : 992,
	XLarge    : 1200,
	XXLarge   : 1400,
	Widescreen: 1600
}

export const BreakpointsFromClasses: { [key: string]: number } = {
	['xs'] : Breakpoints.XSmall,
	['sm'] : Breakpoints.Small,
	['md'] : Breakpoints.Medium,
	['lg'] : Breakpoints.Large,
	['xl'] : Breakpoints.XLarge,
	['xxl']: Breakpoints.XXLarge,
	['ws'] : Breakpoints.Widescreen
}