export namespace Vuex {
	export namespace Modules {
		export namespace Global {
			export enum Names {
				Companies   = 'G_Companies',
				Equipments  = 'G_Equipments',
				Permissions = 'G_Permissions',
				Roles       = 'G_Roles',
				Storages    = 'G_Storages',
				Users       = 'G_Users',
				WorkAreas   = 'G_WorkAreas'
			}
		}

		export namespace Systems {
			export enum Names {
				Module3_Services = 'Module3_Services',
				Module4_Addons   = 'Module4_Addons'
			}
		}
	}
}