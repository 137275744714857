// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { PrimitiveTools }   from '@/Classes/Static/PrimitiveTools'

// Constants
import { Server } from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'
import Vue   from 'vue'

// Interfaces
import { StoreGetters } from '@/Interfaces/Store/Getters'

// Store
import Store from '@/Store/Global/Default'

// Mixin Extend
const MixinFetch = Vue.extend({
	methods: {
		_callEventOnFailedResponse: function(status: number, path: string) {
			if (status === Server.Response.StatusCodes.NULL_RESPONSE) {
				DevelopmentTools.printWarn('[MixinFetch] onServerFailedResponse: Status NULL del Servidor a petición:', path)
				if ('onServerFailedResponse' in this) {
					const self: any = this
					self.onServerFailedResponse(path)
				}
			}
		},

		_callEventOnCaughtFetchException: function(path: string, resolution: Server.Fetching.Resolutions, status: number, error: Error) {
			DevelopmentTools.printWarn('[MixinFetch] onServerCaughtFetchException:', path, resolution, status, error)
			if ('onServerCaughtFetchException' in this) {
				const self: any = this
				self.onServerCaughtFetchException(path, resolution, status, error)
			}
		},

		_catchFetchMessage: function(action: string, path: string, err: Error) {
			DevelopmentTools.printWarn(`[MixinFetch] Excepción ocurrida en petición ${action}:`, path)
			DevelopmentTools.printWarn('[MixinFetch]', err)
		},

		_getBody: function(params?: object): any {
			return { headers: { 'Authorization': `Bearer ${ Store.getters.getStoredAccessToken }` }, params }
		},

		_handleExceptions: async function(status: number, path: string, error: Error) {
			// Manejo de posibles excepciones según el status de la petición original.
			switch (status) {
				case Server.Response.StatusCodes.UNAUTHORIZED:
				case Server.Response.StatusCodes.CONFLICT:
				case Server.Response.StatusCodes.EXPECTATION_FAILED:
				case Server.Response.StatusCodes.UNPROCESSABLE_ENTITY:
					this._callEventOnCaughtFetchException(path, Server.Fetching.Resolutions.NONE, status, error)
					break
				default:
					DevelopmentTools.printError('[MixinFetch] Excepción No Registrada. Status Code:', status)
					break
			}
		},

		_onFetchCaughtException: async function(action: string, path: string, error: any) {
			this._catchFetchMessage(action, path, error)
			await this._handleExceptions(error.response.status, path, error)
			return { status: error.response.status, data: error.response.data }
		},

		_validateFetchCallFor: async function(moduleName: string, path: string, params: any, validation: Server.Fetching.Validations) {
			if (PrimitiveTools.Arrays.isInvalidOrEmpty(Store.getters[`getStored${ moduleName }`])) {
				DevelopmentTools.printWarn('[MixinFetch] doFetchValidationFor(): Realizada Validación para FetchValidations:', validation)
				const statement: any = { action: Server.Fetching.Method.GET, path }
				if (params && typeof params === 'object') statement.params = params
				const response = await this.doFetch(statement)
				if (response.status === Server.Response.StatusCodes.SUCCESS) Store.commit(`store${ moduleName }`, response.data.body)
			}
		},

		/* <=================|==============================|=================> */
		/* <=================| PUBLIC DECLARATION FUNCTIONS |=================> */
		/* <=================|==============================|=================> */

		doFetch: async function(data: { action: Server.Fetching.Method, path: string, body?: any, params?: any }, headers?: any) {
			// Propiedades obligatorias que deben estar presentes en la data.
			const { action, path } = data
			DevelopmentTools.printWarn('[MixinFetch] Iniciado proceso de petición doFetch():', path)

			// Realizar petición con metodo GET.
			if (action === Server.Fetching.Method.GET) {
				try {
					const _headers = this._getBody(data.params)
					const response = await Axios.get(path, _headers)
					this._callEventOnFailedResponse(response.status, path)
					return { status: response.status, data: response.data }
				}
				catch (err) {
					return this._onFetchCaughtException(action, path, err)
				}
			}

			// Realizar petición con metodo POST | PATCH | PUT.
			else if (action === Server.Fetching.Method.POST || action === Server.Fetching.Method.PATCH || action === Server.Fetching.Method.PUT) {
				try {
					const axiosMethod = action === Server.Fetching.Method.POST ? Axios.post : action === Server.Fetching.Method.PATCH ? Axios.patch : Axios.put
					const response = await axiosMethod(path, data.body, headers || this._getBody())
					this._callEventOnFailedResponse(response.status, path)
					return { status: response.status, data: response.data }
				}
				catch (err) {
					return this._onFetchCaughtException(action, path, err)
				}
			}
		},

		doFetchValidationFor: async function(fetch: Server.Fetching.Validations) {
			// Algunas de las rutas ahora requieren de ciertos parametros de la Empresa Administradora.
			const StoreGetters: StoreGetters = Store.getters
			const user = StoreGetters.getStoredUser

			// Parametros para la función.
			let moduleName = '', path = '', params: any = { _idAdminCompany: user._idAdminCompany }

			// Definir los parametros según la ruta solicitada.
			switch (fetch) {
				// Global Validations
				case Server.Fetching.Validations.COMPANIES    : moduleName = 'Companies'    , path = Server.Routes.Companies.GetCompanies;      break
				case Server.Fetching.Validations.EQUIPMENTS   : moduleName = 'Equipments'   , path = Server.Routes.Equipments.GetEquipments;    break
				case Server.Fetching.Validations.INSTALLATIONS: moduleName = 'Installations', path = Server.Routes.CheckLists.GetInstallations; break
				case Server.Fetching.Validations.PERMISSIONS  : moduleName = 'Permissions'  , path = Server.Routes.Permissions.GetPermissions;  break
				case Server.Fetching.Validations.REGIONS      : moduleName = 'Regions'      , path = Server.Routes.Communes.GetCommunes;        break
				case Server.Fetching.Validations.ROLES        : moduleName = 'Roles'        , path = Server.Routes.Roles.GetRoles;              break
				// case Server.Fetching.Validations.SERVICES     : moduleName = 'Services'     , path = Server.Routes.Modules.Module30.ALL;       break
				case Server.Fetching.Validations.STORAGES     : moduleName = 'Storages'     , path = Server.Routes.Storages.GetStorages;        break
				case Server.Fetching.Validations.SYSTEMS      : moduleName = 'Systems'      , path = Server.Routes.Commons.GetSystems;          break
				case Server.Fetching.Validations.USERS        : moduleName = 'Users'        , path = Server.Routes.Users.GetUsers;              break
				case Server.Fetching.Validations.WORKAREAS    : moduleName = 'WorkAreas'    , path = Server.Routes.WorkAreas.GetWorkAreas;      break

				// Module Validations
				case Server.Fetching.Validations.CHECKLIST_ADDONS:
					moduleName = 'Addons'
					path = Server.Routes.CheckLists.GetCheckListsAddons
					break
				case Server.Fetching.Validations.CHECKLIST_SETTINGS:
					moduleName = 'CheckListSettings'
					path = Server.Routes.CheckLists.GetCheckListsSettings
					params._idCompany = user._idCompany
					delete params._idAdminCompany
					break
			}

			// Algunas de las rutas no aceptan ningun parametro GET.
			switch (fetch) {
				case Server.Fetching.Validations.PERMISSIONS:
				case Server.Fetching.Validations.SYSTEMS:
					params = null
					break
			}

			// Ejecutar la Ruta segun la que corresponda.
			this._validateFetchCallFor(moduleName, path, params, fetch)
		}
	}
})

// Exports
export default MixinFetch
export type MixinFetchRef = InstanceType<typeof MixinFetch>