// Dependencies
import Vue from 'vue'

// Mixin Extend
const MixinComponent = Vue.extend({
	data: function() {
		return {
			states: {}
		}
	},

	methods: {
		setStates: function<S = {}>(states: MixinComponentStatesType<S>) {
			for (let name in states) {
				(this.states as S)[name] = states[name]
			}
		}
	}
})

// Exports
export default MixinComponent
export type MixinComponentRef = InstanceType<typeof MixinComponent>
export type MixinComponentStatesType<S> = { [K in keyof S]?: S[K] }