// Constants
import { Breakpoints } from '@/Constants/Global/Breakpoints'

// Dependencies
import Vue from 'vue'

// Mixin Extend
const MixinResponsive = Vue.extend({
	data: function() {
		return {
			responsive: {
				currentBreakpoint: undefined
			},
			breakpoints: [
				Breakpoints.XSmall,
				Breakpoints.Small,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge,
				Breakpoints.XXLarge,
			]
		}
	},

	created: function() {
		this._resizeEventHandler()
	},

	beforeMount: function() {
		window.addEventListener('resize', this._resizeEventHandler)
	},

	beforeDestroy: function() {
		window.removeEventListener('resize', this._resizeEventHandler)
	},

	computed: {
		getResponsiveClass: function(): { [key: string]: true } {
			const prefix = this.getBreakpointPrefix()
			return { [`${ prefix }`]: true }
		},

		getResponsiveClasses: function(): string | object {
			if ((this as any)?.states?.isMobile) return 'mobile'
			return this.getResponsiveClass
		}
	},

	methods: {
		_resizeEventHandler: function() {
			const width = window.innerWidth
			const found = this.breakpoints.find((x: any) => width <= x) || Breakpoints.Widescreen
			this.responsive.currentBreakpoint = found
		},

		getBreakpointPrefix: function() {
			switch (this.responsive.currentBreakpoint) {
				case Breakpoints.XSmall    : return 'xs'
				case Breakpoints.Small     : return 'sm'
				case Breakpoints.Medium    : return 'md'
				case Breakpoints.Large     : return 'lg'
				case Breakpoints.XLarge    : return 'xl'
				case Breakpoints.XXLarge   : return 'xxl'
				case Breakpoints.Widescreen: return 'ws'
			}
		}
	},

	watch: {
		['responsive.currentBreakpoint']: function(newVal: number) {
			if ('_onResponsiveBreakpoint' in this) {
				const self: any = this
				self._onResponsiveBreakpoint(newVal)
			}
		}
	}
})

// Exports
export default MixinResponsive
export type MixinResponsiveRef = InstanceType<typeof MixinResponsive>