// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinCompany    from '@/Mixins/MixinCompany'
import MixinResponsive from '@/Mixins/MixinResponsive'

// TODO: AGREGAR PROPIEDAD fixedBreakpoint PARA LOS COMPONENTES
// QUE USAN ESTE COMPONENTE CON UN TAMAÑO DE LOGO SIEMPRE FIJO (EJ. FORMULARIOS, ETC)

// Component Extend
const CompanyLogo = VueMixins(MixinCompany, MixinResponsive).extend({
	name: 'CompanyLogo',

	props: {
		home: { type: Boolean, default: false }
	},

	computed: {
		_getLogo: function(): string {
			const name = this.getAdministratorCompanyName()
			return name
				? require(`@/Resources/Global/Images/Brands/${ name }.png`)
				: require(`@/Resources/Global/Images/Brands/Kreis.png`)
		}
	},

	methods: {
		onLogoClick: function() {
			if (this.$props.home) {
				this.$emit('onLogoClick')
			}
		}
	}
})

// Exports
export default CompanyLogo
export type CompanyLogoRef = InstanceType<typeof CompanyLogo>